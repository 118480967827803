//export const API_URL = 'http://localhost/golf-leaderboard/api/auth/';
//export const API_URL = 'https://leaderboardapi.ecsion.com/api/auth/';
//export const API_URL = 'https://leaderboard-laravel.azurewebsites.net/api/auth/';
export const API_URL = process.env.REACT_APP_API_URL;

export const AZURE_DOMAIN = process.env.REACT_APP_AZURE_DOMAIN;
export const API_URL_UPDATED = process.env.REACT_APP_CLASSIC_API_GW_URL;

export const AZURE_USER_FLOW = process.env.REACT_APP_AZURE_USER_FLOW;
export const AZURE_CLIENT_ID = process.env.REACT_APP_AZURE_CLIENT_ID;
export const AD_LOGIN_URL = process.env.REACT_APP_AD_LOGIN_URL;
// export const VENUE_GUID = process.env.REACT_APP_VENUE_ID;

export const FRANCHISE_ID = process.env.REACT_APP_FRANCHISE_ID;
export const SUBVENUE_ID = process.env.REACT_APP_SUBVENUE_ID;
export const APP_NAME = process.env.REACT_APP_APP_NAME;
export const APP_VERSION = process.env.REACT_APP_APP_VERSION;
export const APP_GUID = process.env.REACT_APP_APP_GUID;
export const VENUE_GUID = process.env.REACT_APP_VENUE_GUID;
export const FLITE_API_AZURE = process.env.REACT_APP_FLITE_API_AZURE;

export const APP_LOGO = process.env.REACT_APP_LOGO;
export const RESPONSIVE_APP_LOGO = process.env.REACT_APP_RESPONSIVE_LOGO;

export const Days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const appSwitcher = {
  "Venue Manager App": process.env.REACT_APP_BAY_APP,
  "Venue Admin App": process.env.REACT_APP_ADMIN_APP,
  "Account Management App": process.env.REACT_APP_EMPLOYEE_MANAGER_APP,
  "Event Management App": process.env.REACT_APP_EVENT_MANAGEMENT_APP,
  "Floor Management App": process.env.REACT_APP_FLOOR_MANAGEMENT_APP,
};

console.log(appSwitcher);

export const CUSTOM_DOMAIN = process.env.REACT_APP_CUSTOM_DOMAIN;

export const LOGOUT_VARNAME = process.env.REACT_APP_LOGOUT_VARNAME;

export const LOGOUT_COOKIE_NAME = process.env.REACT_APP_LOGOUT_COOKIE_NAME;
